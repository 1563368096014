import React, { lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CircularProgress, Box } from '@mui/material';
import Toast from '@components/toast';
import { GeneralErrorMessage, ErrorMessagesArray } from '@constants/values';
import { UserProfileQuery } from '@queries/userProfile.query';
import ResetPassword from '@containers/reset-password';
import ResendLink from '@containers/resend-link';
import VerifyEmail from '@containers/verify-email';
import CheckEmail from '@containers/check-email';
import AccountBlocked from '@containers/account-blocked';
import RoutesPath from './RoutesPath';
import { LoginType, ProfileType } from '../types/login';

const Login = lazy(() => import('../pages/login'));
const ProfileSetup = lazy(() => import('../pages/profile-setup'));
const Profile = lazy(() => import('../pages/profile'));
const Analytics = lazy(() => import('../pages/analytics'));
const AdminAnalytics = lazy(() => import('../pages/admin-analytics'));
const HomePage = lazy(() => import('../pages/home-page'));
const PreviewPage = lazy(() => import('../pages/preview-page-new'));
const PageInformation = lazy(() => import('../pages/page-information'));
const TemplateSelect = lazy(() => import('../pages/select-template'));

const RouteLayout: React.FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [profile, setProfile] = useState<ProfileType | null>(null);

  const { loading, refetch } = useQuery(UserProfileQuery, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setProfile(data?.getUserProfile);
    },
    onError: data => {
      if (!ErrorMessagesArray.includes(data?.message)) {
        if (data?.message === 'Failed to find user profile') {
          setProfile(null);
          navigate(RoutesPath.setup, { replace: true });
        } else setErrorMessage(data?.message || GeneralErrorMessage);
      }
    },
  });

  useEffect(() => {
    if (profile?.userDetail?.isBlocked)
      navigate(RoutesPath.accountBlocked, { replace: true });
  }, [profile, navigate]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path={RoutesPath.setup}
          element={<ProfileSetup refetchUserProfile={refetch} />}
        />
        <Route
          path={RoutesPath.login}
          element={<Login type={LoginType.SignIn} profileData={profile} />}
        />
        <Route path={RoutesPath.resetPassword} element={<ResetPassword />} />
        <Route path={RoutesPath.newPassword} element={<ResetPassword />} />
        <Route
          path={RoutesPath.verifyEmail}
          element={
            <VerifyEmail profileData={profile} refetchUserProfile={refetch} />
          }
        />
        <Route
          path={RoutesPath.checkResetPasswordEmail}
          element={<CheckEmail type="resetPassword" />}
        />
        <Route
          path={RoutesPath.checkVerifyEmail}
          element={<CheckEmail type="emailVerification" />}
        />
        <Route
          path={RoutesPath.resendResetPasswordLink}
          element={<ResendLink type="resetPassword" />}
        />
        <Route
          path={RoutesPath.resendEmailVerificationLink}
          element={<ResendLink type="emailVerification" />}
        />
        <Route
          path={RoutesPath.home}
          element={
            <HomePage profileData={profile} refetchUserProfile={refetch} />
          }
        />
        <Route
          path={RoutesPath.profile}
          element={
            <Profile profileData={profile} refetchUserProfile={refetch} />
          }
        />
        <Route
          path="/pages/:id/preview"
          element={<PreviewPage profileData={profile} />}
        />
        <Route
          path="/pages/:id"
          element={<PageInformation profileData={profile} />}
        />
        <Route
          path="/pages/:id/analytics"
          element={<Analytics profileData={profile} />}
        />
        <Route
          path={RoutesPath.adminAnalytics}
          element={<AdminAnalytics profileData={profile} />}
        />
        <Route
          path={RoutesPath.templateSelect}
          element={<TemplateSelect profileData={profile} />}
        />
        <Route
          path={RoutesPath.customizeTemplate}
          element={<PageInformation profileData={profile} />}
        />
        <Route
          path={RoutesPath.accountBlocked}
          element={
            <AccountBlocked
              profileData={profile}
              refetchUserProfile={refetch}
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={profile?.userId ? RoutesPath.home : RoutesPath.signup}
            />
          }
        />
      </Routes>
      {errorMessage && (
        <Toast
          message={errorMessage}
          isOpen={Boolean(errorMessage)}
          handleClose={() => setErrorMessage(null)}
          toastBackground="#DA1414"
        />
      )}
    </>
  );
};

export default RouteLayout;
